.btn-link-inline {
  align-items: normal;
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  height: auto !important;
  padding: 0 !important;
  margin: 0;
  perspective-origin: 0 0;
  transform-origin: 0 0;
  width: auto;
  -moz-appearance: none;
  vertical-align: baseline;
  text-align: inherit;
  -webkit-logical-height: 1em;
  /* Chrome ignores auto, so we have to use this hack to set the correct height  */
  -webkit-logical-width: auto;
  /* Chrome ignores auto, but here for completeness */ }

.btn-link-inline:hover {
  text-decoration: underline; }

.invisible-xs-larger,
.invisible-sm-larger,
.invisible-md-larger,
.invisible-lg-larger {
  visibility: hidden; }

.no-user-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Opera and Firefox */ }

@media (max-width: 575.98px) {
  .text-large-xs-smaller {
    font-size: 125%; }
  .invisible-xs-smaller {
    visibility: hidden; }
  .invisible-xs-larger {
    visibility: visible !important; } }

@media (max-width: 767.98px) {
  .text-large-sm-smaller {
    font-size: 125%; }
  .invisible-sm-smaller {
    visibility: hidden; }
  .invisible-sm-larger {
    visibility: visible !important; } }

@media (max-width: 991.98px) {
  .text-large-md-smaller {
    font-size: 125%; }
  .invisible-md-smaller {
    visibility: hidden; }
  .invisible-md-larger {
    visibility: visible !important; } }

@media (max-width: 1199.98px) {
  .text-large-lg-smaller {
    font-size: 125%; }
  .invisible-lg-smaller {
    visibility: hidden; }
  .invisible-lg-larger {
    visibility: visible !important; } }

table.table-scrollable {
  display: flex;
  flex-flow: column;
  max-height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden; }
  table.table-scrollable > thead,
  table.table-scrollable > tfoot {
    display: table;
    table-layout: fixed;
    flex: 0 0 auto;
    width: 100%; }
  table.table-scrollable > tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: auto;
    overflow-x: hidden; }
    table.table-scrollable > tbody > tr {
      display: table;
      table-layout: fixed;
      width: 100%; }

.numerics, .integers, .numbers, input[type="number"] {
  text-align: right; }

.strong {
  font-weight: bold; }

.outline-none {
  outline: none; }

.faded {
  color: #dddddd; }

.selectable,
.nav-tabs li,
.table-single-select tbody tr:not(.noselect),
.table-multi-select tbody tr:not(.noselect),
table.table-hover tbody tr,
.cursor-pointer,
.list-group-item-action,
.checkbox label,
.collapsable {
  cursor: pointer; }

@media print {
  .fixed-bottom {
    margin-top: 2em; }
  nav {
    display: none !important; }
  .tab-pane {
    display: block !important;
    visibility: visible !important;
    opacity: 1 !important; }
  .btn:not(.printable) {
    display: none !important; } }

.td-xs, .width-xs {
  width: 25px !important; }

.td-sm, .width-sm {
  width: 50px !important; }

.td-md, .width-md {
  width: 75px !important; }

.td-lg, .width-lg {
  width: 100px !important; }

.td-xl, .width-xl {
  width: 125px !important; }

.td-xxl, .width-xxl {
  width: 150px !important; }

.td-xxxl, .width-xxxl {
  width: 175px !important; }

table.table-col-min-xs td, table.table-col-min-xs th {
  min-width: 25px !important; }

table.table-col-min-sm td, table.table-col-min-sm th {
  min-width: 50px !important; }

table.table-col-min-md td, table.table-col-min-md th {
  min-width: 75px !important; }

table.table-col-min-lg td, table.table-col-min-lg th {
  min-width: 100px !important; }

table.table-col-min-xl td, table.table-col-min-xl th {
  min-width: 125px !important; }

table.table-sortable thead th {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

@media (min-width: 576px) {
  .small-sm-larger {
    font-size: 80%;
    font-weight: 400; }
  .d-sm-none-larger {
    display: none !important; }
  .inline-label-sm {
    text-align: right; } }

@media (min-width: 768px) {
  .small-md-larger {
    font-size: 80%;
    font-weight: 400; }
  .d-md-none-larger {
    display: none !important; }
  .inline-label-md {
    text-align: right; } }

@media (min-width: 992px) {
  .small-lg-larger {
    font-size: 80%;
    font-weight: 400; }
  .d-lg-none-larger {
    display: none !important; }
  .inline-label-lg {
    text-align: right; } }

@media (min-width: 1200px) {
  .small-xl-larger {
    font-size: 80%;
    font-weight: 400; }
  .d-xl-none-larger {
    display: none !important; }
  .inline-label-xl {
    text-align: right; } }

@media (min-width: 1300px) {
  .small-xxl-larger {
    font-size: 80%;
    font-weight: 400; }
  .d-xxl-none-larger {
    display: none !important; }
  .inline-label-xxl {
    text-align: right; } }

@media (max-width: 575.98px) {
  .small-xs {
    font-size: 80%;
    font-weight: 400; }
  .d-xs-none-smaller {
    display: none !important; }
  .d-sm-none-smaller-than {
    display: none !important; } }

@media (max-width: 767.98px) {
  .small-sm {
    font-size: 80%;
    font-weight: 400; }
  .d-sm-none-smaller {
    display: none !important; }
  .d-md-none-smaller-than {
    display: none !important; } }

@media (max-width: 991.98px) {
  .small-md {
    font-size: 80%;
    font-weight: 400; }
  .d-md-none-smaller {
    display: none !important; }
  .d-lg-none-smaller-than {
    display: none !important; } }

@media (max-width: 1199.98px) {
  .small-lg {
    font-size: 80%;
    font-weight: 400; }
  .d-lg-none-smaller {
    display: none !important; }
  .d-xl-none-smaller-than {
    display: none !important; } }

@media (max-width: 1299.98px) {
  .small-xl {
    font-size: 80%;
    font-weight: 400; }
  .d-xl-none-smaller {
    display: none !important; } }

.custom-fw {
  width: 1.25em !important;
  display: inline-block;
  text-align: center; }

.text-transparent {
  color: rgba(0, 0, 0, 0); }

.text-underline {
  text-decoration: underline; }

.ellipses-truncate {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.hoverAction:hover {
  background-color: #f5f5f5 !important;
  color: var(--primary) !important;
  cursor: pointer; }

.hoverUnderline:hover {
  cursor: pointer;
  text-decoration: underline; }

.tab-content-bordered {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px; }

.x-small {
  font-size: 50%; }

.horizontal-scroll {
  overflow-x: auto; }

.vertical-scroll {
  overflow-y: auto; }

.noFocusRing:focus {
  outline: none !important;
  box-shadow: none !important; }

.noHover {
  background: white !important;
  cursor: default; }

input[type="checkbox"],
label {
  cursor: pointer; }

.tab-pane > .row {
  width: 100%;
  margin-left: 0;
  margin-right: 0; }

@media screen {
  html,
  body,
  #root,
  #root > .App.IWApp {
    height: 100vh !important;
    width: 100vw !important; }
  #root > .App.IWApp > nav {
    min-height: 55px !important;
    z-index: 1030; }
  #root > .App.IWApp > .AppContent {
    position: fixed;
    top: 55px;
    bottom: 18px;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column; }
    #root > .App.IWApp > .AppContent > .container,
    #root > .App.IWApp > .AppContent > .container-fluid {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0; }
  #root > .App.IWApp > footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    height: 18px !important; }
  .fill-height {
    overflow-y: hidden; }
  .fill-height,
  .fill-height-scroll {
    display: flex;
    min-height: 0;
    max-height: 100%;
    flex: 1; }
  .fill-height:not(.row),
  .fill-height-scroll:not(.row) {
    flex-direction: column; }
  .fill-height-scroll {
    overflow-y: auto; }
  .fill-height-scroll:not(.row) {
    flex: 1 1 auto; } }

input[type='file'] {
  width: 1px !important;
  height: 1px !important;
  opacity: 0; }

img.img-inline {
  max-width: 100%;
  height: 1.25em;
  object-fit: contain;
  font-size: inherit !important; }

.img-xs {
  height: auto;
  object-fit: contain;
  max-width: 2em;
  max-height: 2em; }

.img-sm {
  height: auto;
  object-fit: contain;
  max-width: 4em;
  max-height: 4em; }

.img-md {
  height: auto;
  object-fit: contain;
  max-width: 8em;
  max-height: 8em; }

.img-lg {
  height: auto;
  object-fit: contain;
  max-width: 12em;
  max-height: 12em; }

.img-xl {
  height: auto;
  object-fit: contain;
  max-width: 16em;
  max-height: 16em; }

.none-xs {
  display: none; }

@media (min-width: 768px) {
  .none-sm {
    display: none; } }

@media (min-width: 992px) {
  .none-md {
    display: none; } }

@media (min-width: 1200px) {
  .none-lg {
    display: none; } }

.text-strike-through {
  text-decoration: line-through; }

@media print {
  footer {
    display: none; }
  .page-break-after {
    page-break-after: always !important;
    position: static !important; }
  .page-break-before {
    page-break-before: always !important;
    position: static !important; } }

.modal-content {
  max-height: 85vh; }
  .modal-content .modal-body:not(.no-overflow-scroll) {
    overflow-y: auto; }

.inputSelect.disabledLink,
.inputSelect[disabled] {
  border-color: transparent !important;
  background-color: transparent !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important; }

.inputSelect.disabledLink {
  color: var(--primary); }

.System_MessageBox {
  position: fixed !important;
  bottom: 2em;
  z-index: 10000;
  max-height: 10em;
  overflow: auto; }

@media (min-width: 768px) {
  .System_MessageBox {
    left: 25%;
    width: 50%; } }

@media (max-width: 767.98px) {
  .System_MessageBox {
    left: 2.5%;
    width: 95%; } }

.masterDetail {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 0; }
  .masterDetail > .masterDetailMaster {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-right: 1px solid gainsboro; }
  .masterDetail > .masterDetailDetail {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    min-height: 0;
    min-width: 0; }

.masterDetail-xs > .masterDetailMaster.isOpen {
  display: none; }

.masterDetail-xs > .masterDetailMaster {
  border-right: none !important;
  flex: 1;
  min-height: 0; }

.masterDetail-xs > .masterDetailDetail.hideWhenSmall {
  display: none !important; }

@media (max-width: 768px) {
  .masterDetail-sm > .masterDetailMaster.isOpen {
    display: none; }
  .masterDetail-sm > .masterDetailMaster {
    border-right: none !important;
    flex: 1;
    min-height: 0; }
  .masterDetail-sm > .masterDetailDetail.hideWhenSmall {
    display: none !important; } }

@media (max-width: 992px) {
  .masterDetail-md > .masterDetailMaster.isOpen {
    display: none; }
  .masterDetail-md > .masterDetailMaster {
    border-right: none !important;
    flex: 1;
    min-height: 0; }
  .masterDetail-md > .masterDetailDetail.hideWhenSmall {
    display: none !important; } }

@media (max-width: 1200px) {
  .masterDetail-lg > .masterDetailMaster.isOpen {
    display: none; }
  .masterDetail-lg > .masterDetailMaster {
    border-right: none !important;
    flex: 1;
    min-height: 0; }
  .masterDetail-lg > .masterDetailDetail.hideWhenSmall {
    display: none !important; } }

@media (max-width: 1200px) {
  .masterDetail-xl > .masterDetailMaster.isOpen {
    display: none; }
  .masterDetail-xl > .masterDetailMaster {
    border-right: none !important;
    flex: 1;
    min-height: 0; }
  .masterDetail-xl > .masterDetailDetail.hideWhenSmall {
    display: none !important; } }

.DateRangeDD {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem; }

.DateRangeDD:not(.py-0):not(.p-0) {
  height: calc(1.5em + 0.75rem + 2px); }
  .DateRangeDD:not(.py-0):not(.p-0) .DateRangeLB {
    top: calc(1.5em + 0.75rem + 2px) !important; }

.DateRangeLB {
  position: absolute;
  cursor: auto;
  color: black;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: calc(1.5em + 2px);
  left: 0;
  z-index: 3001;
  font-family: arial, sans-serif;
  font-size: 15px;
  line-height: 1em; }

.DateRangeLB:before, .DateRangeLB:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: ''; }

.DateRangeLB:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc; }

.DateRangeLB:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent; }

.DateRangeLB.OpensLeft:before {
  right: 9px; }

.DateRangeLB.OpensLeft:after {
  right: 10px; }

.DateRangeLB.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto; }

.DateRangeLB.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto; }

.DateRangeLB.OpensRight:before {
  left: 9px; }

.DateRangeLB.OpensRight:after {
  left: 10px; }

.DateRangeLB.drop-up {
  margin-top: -7px; }

.DateRangeLB.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc; }

.DateRangeLB.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff; }

.DateRangeLB.single .DateRangeLB .ranges, .DateRangeLB.single .drp-calendar {
  float: none; }

.DateRangeLB.single .drp-selected {
  display: none; }

.DateRangeLB.show-calendar .drp-calendar {
  display: block; }

.DateRangeLB.show-calendar .drp-buttons {
  display: block; }

.DateRangeLB.auto-apply .drp-buttons {
  /*display: none;*/ }

.DateRangeLB .drp-calendar {
  /*display:   none;*/
  max-width: 270px; }

.DateRangeLB .drp-calendar.left {
  padding: 8px 0 8px 8px; }

.DateRangeLB .drp-calendar.right {
  padding: 8px; }

.DateRangeLB .drp-calendar.single .calendar-table {
  border: none; }

.DateRangeLB .calendar-table .prev, .DateRangeLB .calendar-table .next {
  cursor: pointer; }

.DateRangeLB .calendar-table .next span, .DateRangeLB .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px; }

.DateRangeLB .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.DateRangeLB .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.DateRangeLB .calendar-table th, .DateRangeLB .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent; }

.DateRangeLB .calendar-table td {
  cursor: pointer; }

.DateRangeLB .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff; }

.DateRangeLB .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse; }

.DateRangeLB td.available:hover, .DateRangeLB th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit; }

.DateRangeLB td.week, .DateRangeLB th.week {
  font-size: 80%;
  color: #ccc; }

.DateRangeLB td.off, .DateRangeLB td.off.in-range, .DateRangeLB td.off.start-date, .DateRangeLB td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999; }

.DateRangeLB td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0; }

.DateRangeLB td.start-date {
  border-radius: 4px 0 0 4px; }

.DateRangeLB td.end-date {
  border-radius: 0 4px 4px 0; }

.DateRangeLB td.start-date.end-date {
  border-radius: 4px; }

.DateRangeLB td.active, .DateRangeLB td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff; }

.DateRangeLB th.month {
  width: auto; }

.DateRangeLB td.disabled, .DateRangeLB option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through; }

.DateRangeLB select.monthselect, .DateRangeLB select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default; }

.DateRangeLB select.monthselect {
  margin-right: 2%;
  width: 56%; }

.DateRangeLB select.yearselect {
  width: 40%; }

.DateRangeLB select.hourselect, .DateRangeLB select.minuteselect, .DateRangeLB select.secondselect, .DateRangeLB select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px; }

.DateRangeLB .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative; }

.DateRangeLB .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed; }

.DateRangeLB .drp-headers {
  clear: both;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  line-height: 12px;
  vertical-align: middle;
  font-size: 12px;
  cursor: pointer; }

.DateRangeLB .drp-headers:hover {
  background-color: #eee; }

.DateRangeLB .drp-headers.active {
  background-color: #08c;
  color: #fff; }

.DateRangeLB .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  line-height: 12px;
  vertical-align: middle; }

.DateRangeLB .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px; }

.DateRangeLB .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px; }

.DateRangeLB.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd; }

.DateRangeLB.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd; }

.DateRangeLB.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd; }

.DateRangeLB.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd; }

.DateRangeLB .ranges {
  float: none;
  text-align: left;
  margin: 0; }

.DateRangeLB.show-calendar .ranges {
  margin-top: 8px; }

.DateRangeLB .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%; }

.DateRangeLB .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer; }

.DateRangeLB .ranges li:hover {
  background-color: #eee; }

.DateRangeLB .ranges li.active {
  background-color: #08c;
  color: #fff; }

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .DateRangeLB {
    width: auto; }
  .DateRangeLB .ranges ul {
    width: 140px; }
  .DateRangeLB.single .ranges ul {
    width: 100%; }
  .DateRangeLB.single .drp-calendar.left {
    clear: none; }
  .DateRangeLB.single .ranges, .DateRangeLB.single .drp-calendar {
    float: left; }
  .DateRangeLB {
    direction: ltr;
    text-align: left; }
  .DateRangeLB .drp-calendar.left {
    clear: left;
    margin-right: 0; }
  .DateRangeLB .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .DateRangeLB .drp-calendar.right {
    margin-left: 0; }
  .DateRangeLB .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .DateRangeLB .drp-calendar.left .calendar-table {
    padding-right: 8px; }
  .DateRangeLB .ranges, .DateRangeLB .drp-calendar {
    float: left; } }

@media (min-width: 730px) {
  .DateRangeLB .ranges {
    width: auto; }
  .DateRangeLB .ranges {
    float: left; }
  .DateRangeLB.rtl .ranges {
    float: right; }
  .DateRangeLB .drp-calendar.left {
    clear: none !important; } }

.System_ActivityOverlay_Control {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.75); }

.System_ActivityOverlay {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  align-items: center;
  justify-content: center; }
